import {
  ASSOCIATED_MERCHANDIZING_SCANS,
  MERCHANDIZING_SCAN_STATISTICS,
  initialState
} from './utils.js'

export const LOAD_ASSOCIATED_MERCHANDIZING_SCANS = 'associatedMerchandizingScans/LOAD'
export const TOGGLE_ASSOCIATED_MERCHANDISING_SCAN = 'associatedMerchandizingScans/TOGGLE_ASSOCIATED_MERCHANDISING_SCAN'
export const LOAD_MERCHANDIZING_SCAN_STATISTICS = 'associatedMerchandizingScans/LOAD_MERCHANDIZING_SCAN_STATISTICS'

export default function associatedMerchandizingScansReducer(state, action) {
  switch (action.type) {
    case LOAD_ASSOCIATED_MERCHANDIZING_SCANS: {
      return state.withMutations(s => {
        s.mergeIn([ASSOCIATED_MERCHANDIZING_SCANS], action.associatedMerchandizingScans)
      })
    }
    case TOGGLE_ASSOCIATED_MERCHANDISING_SCAN: {
      const { amsIds } = action
      return state.withMutations(s => {
        amsIds.forEach(id => {
          s.updateIn([ASSOCIATED_MERCHANDIZING_SCANS, id, 'selected'], selected => !selected)
        })
      })
    }
    case LOAD_MERCHANDIZING_SCAN_STATISTICS: {
      return state.withMutations(s => {
        s.setIn([MERCHANDIZING_SCAN_STATISTICS], action.merchandizingScanStatistics)
      })
    }
    default:
      return state || initialState
  }
}
