import * as am5 from '@amcharts/amcharts5'
import * as am5percent from '@amcharts/amcharts5/percent'
import { generateGraphTooltipHTML } from '../utils'

export type MultiDataPieChartContext = {
  studioColor: string
  studioName: string
  totalMpv: number
  totalShareOfVoice: number
}

export const setAdapter = (template, property, colorField) => {
  template.adapters.add(property, (_value, target) => {
    const dataContext = target.dataItem.dataContext as MultiDataPieChartContext
    return am5.color(dataContext[colorField])
  })
}

export const createPieChart = (root: am5.Root, innerRadius: number) => {
  const chart = root.container.children.push(
    am5percent.PieChart.new(root, {
      layout: root.verticalLayout,
      innerRadius: am5.percent(innerRadius),
    })
  )

  return chart
}

export const createLegend = (root: any, series: any, labels: boolean = false) => {
  const legend = root.container.children.push(
    am5.Legend.new(root, {
      centerX: am5.p100,
      x: am5.p100,
      centerY: am5.percent(0),
      y: am5.percent(0),
      layout: root.horizontalLayout,
    })
  )

  legend.data.setAll(series.dataItems)

  if (labels) {
    legend.labels.template.set('text', '{categoryX}')
  }

  return legend
}

export const setTooltipAdapter = (template, metric, scanDate) => {
  return template.add('tooltipHTML', (_html, target) => {
    const dataContext = target.dataItem.dataContext as MultiDataPieChartContext
    return generateGraphTooltipHTML(dataContext, metric, scanDate)
  })
}
