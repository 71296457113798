import React from 'react'
import { useLayoutEffect } from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'

import { MerchandizingScanStatistic } from 'Interfaces/*'
import { formatGraphData } from '../../utils'
import { MultiDataPieChartContext, createLegend, setTooltipAdapter } from '../chartUtils'
import styles from '../styles.module.scss'

type XYChartTypes = {
  metric: string
  data: MerchandizingScanStatistic[]
  scanDate: string
}

const XYChart: React.FC<XYChartTypes> = ({ data, metric, scanDate }) => {
  useLayoutEffect(() => {
    const root = am5.Root.new('chartdiv')
    root._logo.dispose()
    root.setThemes([am5themes_Animated.new(root)])

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        layout: root.verticalLayout,
        width: am5.percent(100),
        height: am5.percent(100),
        paddingTop: 50,
      })
    )

    const xRenderer = am5xy.AxisRendererX.new(root, {})
    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: xRenderer,
        categoryField: 'studioName',
      })
    )

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    )

    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'Series',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: metric,
        categoryXField: 'studioName',
      })
    )

    series.columns.template.setAll({
      fillOpacity: 0.8,
      strokeOpacity: 0,
      tooltipY: am5.percent(0),
      centerY: am5.percent(100),
    })

    setTooltipAdapter(series.columns.template.adapters, metric, scanDate)

    series.columns.template.adapters.add('fill', (_fill, target) => {
      const dataContext = target.dataItem.dataContext as MultiDataPieChartContext
      return am5.color(dataContext.studioColor)
    })

    const formattedData = formatGraphData(data)

    series.data.setAll(formattedData)
    xAxis.data.setAll(formattedData)

    createLegend(root, series, true)

    return () => {
      root.dispose()
    }
  }, [data, metric])

  return <div id='chartdiv' className={styles.chartWrapper}></div>
}
export default XYChart
