import AssociatedMerchandizingScanRecord from 'Records/associatedMerchandizingScanRecord'
import MerchandizingScanStatisticsRecord from 'Records/merchandizingScanStatisticsRecord'
import {
  LOAD_ASSOCIATED_MERCHANDIZING_SCANS,
  TOGGLE_ASSOCIATED_MERCHANDISING_SCAN,
  LOAD_MERCHANDIZING_SCAN_STATISTICS,
} from 'Reducers/app/associatedMerchandizingScanReducer'
import { Map, OrderedMap, fromJS } from 'immutable'
import { readEndpoint } from 'Actions/apiActions'

export const loadAssociatedMerchandizingScans = associatedMerchandizingScans => ({
  type: LOAD_ASSOCIATED_MERCHANDIZING_SCANS,
  associatedMerchandizingScans,
})

export const associatedMerchandizingScanRecordsMapper = (memo, item) => {
  return memo.set(
    item.id,
    new AssociatedMerchandizingScanRecord({
      id: item.id,
      ...item.attributes,
      links: new Map(item.links),
      meta: new Map(item.meta),
      relationships: fromJS(item.relationships),
    })
  )
}

export const normalizeAndLoadAssociatedMerchandizingScans = data => {
  const associatedMerchandizingScans = data.reduce((memo, item) => {
    return memo.set(item.id, new AssociatedMerchandizingScanRecord({ ...item }))
  }, new OrderedMap())

  return loadAssociatedMerchandizingScans(associatedMerchandizingScans)
}

export const toggleAssociatedMerchandizingScan = amsIds => dispatch => {
  dispatch({
    type: TOGGLE_ASSOCIATED_MERCHANDISING_SCAN,
    amsIds,
  })
}

export const retrieveMerchandizingScanStatistics = (scanIds, studioIds) => dispatch => {
  const url = `merchandizing/scans/statistics?limit=1000&page=1&filter[studio_ids]=${studioIds.join(',')}&filter[scan_ids]=${scanIds.join(',')}`

  return readEndpoint(url, 'GET').then(response => {
    dispatch(normalizeAndLoadMerchandizingScanStatistics(response.merchandizingScanStatistics))
  })
}

export const normalizeAndLoadMerchandizingScanStatistics = data => dispatch => {
  const merchandizingScanStatistics = data.reduce((memo, item) => {
    return memo.set(item.studio.id, new MerchandizingScanStatisticsRecord({ ...item, id: item.studio.id }))
  }, new OrderedMap())

  dispatch({
    type: LOAD_MERCHANDIZING_SCAN_STATISTICS,
    merchandizingScanStatistics,
  })
}
