import React from 'react';
import { PerformancePageEnum } from './constants';
import cx from 'classnames';
import styles from './Toolbar.module.scss';


type Props = {
  performancePage: PerformancePageEnum;
  handleSetPerformancePage: (page: PerformancePageEnum) => void;
}
const PerformanceButtons: React.FC<Props> = ({ performancePage, handleSetPerformancePage }) => {
  const performancePages = [
    PerformancePageEnum.Competitor,
    PerformancePageEnum.Platform,
  ];

  return (
    <div className='bp5-button-group'>
      {performancePages.map((page) => (
        <button
          key={page}
          className={cx('bp5-button', styles.btnDefault, {
            [styles.active]: performancePage === page,
          })}
          onClick={() => handleSetPerformancePage(page)}
        >
          {page}
        </button>
      ))}
    </div>
  );
}

export default PerformanceButtons;