import React from 'react'

import { Menu, MenuItem, Popover, PopoverInteractionKind, Position, Tooltip } from '@blueprintjs/core'

import PlatformAndTerritoryFilter from 'Components/PlatformAndTerritoryFilter'
import useToolbarActions from 'Components/ScanPage/useToolbarActions'
import { getTitleFilters, getTitlesLoading } from 'Selectors/index'
import cx from 'classnames'
import inflection from 'inflection'
import { useSelector } from 'react-redux'
import ActionConfirmationModal from './ActionConfirmationModal'
import TitleNameSelector from './TitleNameSelector'
import styles from './Toolbar.module.scss'
import { FILTER, PIN, UNPIN } from './constants'
import useContainerActions from './hooks/useContainerActions'
import ContainerToolbarButton from './ContainerToolbarButton'

const Toolbar = ({ client, pinFilter, setPinFilter }) => {
  const { filter } = useSelector(state => ({
    filter: getTitleFilters(state),
  }))

  const isTitlesLoading = useSelector(state => getTitlesLoading(state))

  const {
    clearQuery,
    updateQuery,
    toggleMovie,
    toggleSeries,
    toggleAll,
    relocateTitles,
    enablePriority,
    retryUnavailableTitles,
    disablePromoted,
    enablePromoted,
    setNewCandidatesFilter,
    refreshAmazonArtworks,
    setLetterFilter,
    toggleEnabledFilter,
    markUnavailableForeverTitles,
    stateFilterClear,
    stateFilterSet,
    reloadTitles,
    markUnavailableTitles,
  } = useToolbarActions({})

  const { handleAction, handleCloseModal, handleSubmitAction, isModalOpen, triggeredActionName } = useContainerActions()

  const togglePin = () => {
    setPinFilter(prevState => !prevState)
  }

  return (
    <nav
      className={cx('bp5-navbar', {
        [styles.header]: !pinFilter,
        [styles.staticHeader]: pinFilter,
      })}
    >
      <div className='bp5-navbar-group bp5-align-left'>
        <div className='bp5-button-group'>
          <ContainerToolbarButton text='All' active={filter.all} onClick={toggleAll} />
          <ContainerToolbarButton text='Films' active={filter.movies} onClick={toggleMovie} />
          <ContainerToolbarButton text='TV Seasons' active={filter.tv} onClick={toggleSeries} />
        </div>
        <div className={cx(styles.divider, 'bp5-navbar-divider')} />
        <div className='bp5-button-group'>
          <ContainerToolbarButton
            text='All'
            active={filter.state === '' && !filter.newCandidates}
            onClick={stateFilterClear}
          />
          {filter.stateFilters().map(state => (
            <ContainerToolbarButton
              text={inflection.titleize(state)}
              active={filter.state === state}
              onClick={() => stateFilterSet(state)}
              key={state}
            />
          ))}
          <ContainerToolbarButton
            text='New Candidates'
            active={filter.newCandidates}
            onClick={setNewCandidatesFilter}
          />
        </div>
        <div className='bp5-navbar-group bp5-align-left'>
          <div className={cx(styles.divider, 'bp5-navbar-divider')} />
          <div className='bp5-button-group'>
            <ContainerToolbarButton
              text='All'
              active={!filter.promoted && !filter.priority}
              onClick={disablePromoted}
            />
            <ContainerToolbarButton text='Featured' active={filter.promoted} onClick={enablePromoted} />
            {client && client.tagsEnabled && (
              <ContainerToolbarButton text='Priority' active={filter.priority} onClick={enablePriority} />
            )}
          </div>
        </div>
        <div className={cx(styles.divider, 'bp5-navbar-divider')} />
        <div className='bp5-button-group'>
          <ContainerToolbarButton text='Enabled' active={filter.enabled} onClick={toggleEnabledFilter} />
          <ContainerToolbarButton text='Disabled' active={!filter.enabled} onClick={toggleEnabledFilter} />
        </div>
        <div className={cx(styles.divider, 'bp5-navbar-divider')} />
        <Popover
          interactionKind={PopoverInteractionKind.CLICK}
          popoverClassName='bp5-popover-padding-none'
          position={Position.BOTTOM_RIGHT}
          content={
            <Menu className='bp5-menu'>
              <MenuItem
                className='bp5-icon-search'
                text='Relocate all pending and errored'
                onClick={() => handleAction('relocate all pending and errored', relocateTitles)}
              />
              <MenuItem
                className='bp5-icon-automatic-updates'
                text='Retry all unavailable'
                onClick={() => handleAction('retry unavailable', retryUnavailableTitles)}
              />
              <MenuItem
                className='bp5-icon-one-column'
                text='Bulk Unavailable Forever'
                onClick={() => handleAction('mark unavailable forever', markUnavailableForeverTitles)}
              />
              <MenuItem
                className='bp5-icon-pause'
                text='Bulk Unavailable'
                onClick={() => handleAction('mark unavailable', markUnavailableTitles)}
              />
              <MenuItem
                className='bp5-icon-image-rotate-left'
                text='Refresh all amazon artworks'
                onClick={() => handleAction('refresh amazon artworks', refreshAmazonArtworks)}
              />
            </Menu>
          }
        >
          <ContainerToolbarButton text='Actions' className={cx('bp5-button', 'bp5-icon-cog')} />
        </Popover>
        <div className={cx(styles.divider, 'bp5-navbar-divider')} />
        <div className='bp5-input-group'>
          <span className='bp5-icon bp5-icon-search' />
          <input
            className='bp5-round bp5-input'
            value={filter.query}
            placeholder='Search'
            dir='auto'
            onChange={e => updateQuery(e.target.value)}
            onKeyPress={e => {
              if (e.key === 'Enter') reloadTitles()
            }}
            disabled={isTitlesLoading}
          />
        </div>

        <div className={cx(styles.divider, 'bp5-navbar-divider')} />
        <Popover
          interactionKind={PopoverInteractionKind.CLICK}
          position={Position.BOTTOM_RIGHT}
          content={<PlatformAndTerritoryFilter newUI regionsAndPlatforms />}
        >
          <Tooltip content='Territory/Platform Filter' position={Position.BOTTOM_LEFT}>
            <ContainerToolbarButton className={cx('bp5-icon-filter')} disabled={isTitlesLoading} />
          </Tooltip>
        </Popover>

        {filter.hasActiveQuery() && (
          <div className='bp5-navbar-group'>
            <div className={cx(styles.divider, 'bp5-navbar-divider')} />
            <ContainerToolbarButton
              text='Clear Search'
              className={cx('bp5-icon-cross')}
              onClick={clearQuery}
              disabled={isTitlesLoading}
            />
          </div>
        )}
        <div className={cx(styles.divider, 'bp5-navbar-divider')} />
        <TitleNameSelector
          selectedFilter={filter.letter}
          setLetterFilter={setLetterFilter}
          isTitlesLoading={isTitlesLoading}
        />
        <ActionConfirmationModal
          isOpen={isModalOpen}
          actionName={triggeredActionName}
          onSubmit={handleSubmitAction}
          onClose={handleCloseModal}
        />
        {/* TODO: This tooltip and button for pinning/unpinning need to be implemented later. */}
        {/*<div className={cx(styles.divider, 'bp5-navbar-divider')} />
          <Tooltip content={pinFilter ? `${UNPIN} ${FILTER}` : `${PIN} ${FILTER}`} position={Position.BOTTOM_LEFT}>
          <ContainerToolbarButton
            text={pinFilter ? UNPIN : PIN}
            className={cx({
              ['bp5-icon-pin']: !pinFilter,
              ['bp5-icon-unpin']: pinFilter,
            })}
            onClick={togglePin}
          />
        </Tooltip> */}
      </div>
    </nav>
  )
}

export default Toolbar
