import ClientSalesSummaryRecord from 'Records/clientSalesSummaryRecord'
import CompetitorFilterRecord from 'Records/competitorFilterRecord'
import MarketShareFilterRecord from 'Records/marketShareFilterRecord'
import SpotFilterRecord from 'Records/merchandizing/spotFilterRecord'
import ScanFilterRecord from 'Records/scanFilterRecord'
import ScanSummary from 'Records/scanSummaryRecord'
import TableauRecord from 'Records/tableauRecord'
import TitleFilterRecord from 'Records/titleFilterRecord'
import { Map, OrderedMap, List } from 'immutable'

export const CLIENTS = 'clients'
export const CLIENT = 'client'
export const CLIENT_META = 'client_meta'
export const CLIENT_COMPETITORS = 'client_competitors'
export const BRANDS = 'brands'
export const TITLES = 'titles'
export const TITLES_FOR_CORRECTION = 'titles_for_correction'
export const COMPETITORS = 'competitors'
export const PLATFORM_TITLES = 'platform_titles'
export const SCANS = 'scans'
export const AUDITS = 'audits'
export const AUDIT_ARTWORKS = 'audit_artworks'
export const TITLE_AUDITS = 'title_audits'
export const PLATFORMS = 'platforms'
export const TERRITORIES = 'territories'
export const DATA = 'data'
export const LINKS = 'links'
export const RELATIONSHIPS = 'links'
export const PRICES = 'prices'
export const CHANNELS = 'channels'
export const AUDIT_PRICES = 'audit_prices'
export const AUDIT_ACTIONS = 'audit_actions'
export const AUDIT_EXCEPTIONS = 'audit_exceptions'
export const FILTERS = 'filters'
export const META = 'meta'
export const SUMMARY = 'summary'
export const TAGS = 'tags'
export const TAGGINGS = 'taggings'
export const SCAN_DOWNLOADS = 'scan_downloads'
export const OPTIMIZATION_SCORES = 'optimization_scores'
export const CLIENT_SALES_SUMMARY = 'client_sales_summary'
export const MARKET_SHARES = 'market_shares'
export const CLIENT_DASHBOARD = 'client_dashboard'
export const TABLEAU_TOKEN = 'tableau_token'
export const PUBLISHERS = 'publishers'
export const ARTWORK_COMPARISONS = 'artworkComparisons'
export const LOCATION_CANDIDATES = 'locationCandidates'
export const MERCHANDIZING = 'merchandizing'
export const PAGES = 'pages'
export const SECTIONS = 'sections'
export const SPOTS = 'spots'
export const MATCHES = 'matches'
export const ASSOCIATED_MERCHANDIZING_SCANS = 'associatedMerchandizingScans'
export const STUDIOS = 'studios'
export const MERCHANDIZING_SCAN_STATISTICS = 'merchandizingScanStatistitcs'

export const initialState = new Map({
  [CLIENTS]: new Map(),
  [CLIENT]: new Map(),
  [CLIENT_META]: new Map(),
  [CLIENT_COMPETITORS]: new List(),
  [TITLES]: new Map({
    [DATA]: new OrderedMap(),
    [LINKS]: new OrderedMap(),
    [META]: new OrderedMap(),
    [FILTERS]: new TitleFilterRecord(),
  }),
  [TITLES_FOR_CORRECTION]: new Map({
    [DATA]: new OrderedMap(),
    [META]: new OrderedMap(),
    [LINKS]: new OrderedMap(),
  }),
  [COMPETITORS]: new Map({
    [DATA]: new OrderedMap(),
    [LINKS]: new OrderedMap(),
    [META]: new OrderedMap(),
    [FILTERS]: new CompetitorFilterRecord(),
  }),
  [PLATFORM_TITLES]: new Map({
    [DATA]: new OrderedMap(),
    [LINKS]: new OrderedMap(),
    [META]: new Map(),
  }),
  [SCANS]: new Map({
    [DATA]: new OrderedMap(),
    [FILTERS]: new ScanFilterRecord(),
    [SUMMARY]: new ScanSummary(),
    [META]: new OrderedMap(),
  }),
  [MERCHANDIZING]: new Map({
    [SCANS]: new Map({
      [DATA]: new OrderedMap(),
    }),
    [PAGES]: new Map({
      [DATA]: new OrderedMap(),
      [META]: new OrderedMap(),
    }),
    [SPOTS]: new Map({
      [DATA]: new OrderedMap(),
      [LINKS]: new OrderedMap(),
      [META]: new OrderedMap(),
      [FILTERS]: new SpotFilterRecord(),
    }),
    [SECTIONS]: new Map({
      [DATA]: new OrderedMap(),
      [META]: new OrderedMap(),
    }),
    [MATCHES]: new Map({
      [DATA]: new OrderedMap(),
      [META]: new Map(),
    }),
    [PLATFORM_TITLES]: new Map({
      [DATA]: new OrderedMap(),
    }),
    [SCAN_DOWNLOADS]: new Map({}),
  }),
  [ASSOCIATED_MERCHANDIZING_SCANS]: new OrderedMap(),
  [AUDITS]: new OrderedMap(),
  [AUDIT_ARTWORKS]: new OrderedMap(),
  [TITLE_AUDITS]: new Map({
    [DATA]: new OrderedMap(),
    [LINKS]: new OrderedMap(),
    [META]: new OrderedMap(),
  }),
  [PLATFORMS]: new Map(),
  [TERRITORIES]: new OrderedMap(),
  [PRICES]: new Map(),
  [AUDIT_PRICES]: new Map(),
  [CHANNELS]: new Map(),
  [AUDIT_ACTIONS]: new Map({
    [DATA]: new OrderedMap(),
    [LINKS]: new OrderedMap(),
    [META]: new OrderedMap(),
    [RELATIONSHIPS]: new OrderedMap(),
  }),
  [AUDIT_EXCEPTIONS]: new Map({
    [DATA]: new OrderedMap(),
    [LINKS]: new OrderedMap(),
    [META]: new OrderedMap(),
  }),
  [OPTIMIZATION_SCORES]: new Map({
    [TITLE_AUDITS]: new OrderedMap(),
    [BRANDS]: new OrderedMap(),
  }),
  [CLIENT_SALES_SUMMARY]: new ClientSalesSummaryRecord(),
  [MARKET_SHARES]: new Map({
    [DATA]: new OrderedMap(),
    [FILTERS]: new MarketShareFilterRecord(),
    [META]: new Map(),
  }),
  [TABLEAU_TOKEN]: new TableauRecord(),
  [PUBLISHERS]: new OrderedMap(),
  [ARTWORK_COMPARISONS]: new OrderedMap(),
  [LOCATION_CANDIDATES]: new Map({
    [DATA]: new OrderedMap(),
    [META]: new Map(),
  }),
  [TAGS]: new OrderedMap(),
  [STUDIOS]: new OrderedMap(),
  [SCAN_DOWNLOADS]: new Map({}),
  [MERCHANDIZING_SCAN_STATISTICS]: new OrderedMap(),
})

export function deselectScan({ mutable, scan }) {
  mutable.setIn([SCANS, DATA, scan.id, 'selected'], false)
}

export function deselectScans({ mutable }) {
  const selected = mutable.getIn([SCANS, DATA]).filter(c => c.selected)
  selected.forEach(scan =>
    deselectScan({
      scan,
      mutable,
    })
  )
}

export function deselectPlatformTitle({ mutable, platformTitle }) {
  mutable.setIn([PLATFORM_TITLES, DATA, platformTitle.id, 'selected'], false)
}

export function deselectPlatformTitles({ mutable }) {
  const selected = mutable.getIn([PLATFORM_TITLES, DATA]).filter(c => c.selected)
  selected.forEach(platformTitle =>
    deselectPlatformTitle({
      platformTitle,
      mutable,
    })
  )
}

export function removePlatformTitle({ mutable, platformTitleId }) {
  mutable.deleteIn([PLATFORM_TITLES, DATA, platformTitleId])
}

export function removeTaggings({ mutable, taggingId }) {
  mutable.deleteIn([TAGGINGS, taggingId])
}

export function removeTag({ mutable, tagId }) {
  mutable.deleteIn([TAGS, tagId])
}

export function removeScanDownload({ mutable, scanDownloadId }) {
  mutable.deleteIn([SCAN_DOWNLOADS, scanDownloadId])
}

export function removeMerchandizingScanDownload({ mutable, scanDownloadId }) {
  mutable.deleteIn([MERCHANDIZING, SCAN_DOWNLOADS, scanDownloadId])
}

export function updateRecords(newRecords, mutable, path) {
  newRecords.forEach(newRecord => {
    mutable.updateIn([...path, newRecord.id], record =>
      record && Map.isMap(record) ? record.update(newRecord) : newRecord
    )
  })
}
