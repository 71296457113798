import { Record } from "immutable";

export interface MerchandizingScanStatistics {
  id: string
  studio: {
    id: string
    color: string
    name: string
    presentInMerchandisingFilter: boolean
  }
  totalMpv: string
  totalShareOfVoice: string
  totalSpots: number
  totalStudioSpots: number
}

export const defaultMerchandizingScanStatistics: MerchandizingScanStatistics = {
  id: '',
  studio: {
    id: '',
    color: '',
    name: '',
    presentInMerchandisingFilter: false
  },
  totalMpv: '0',
  totalShareOfVoice: '0',
  totalSpots: 0,
  totalStudioSpots: 0
}

export default class MerchandizingScanStatisticsRecord extends Record(defaultMerchandizingScanStatistics) {
  get<T extends keyof MerchandizingScanStatistics>(value: T): MerchandizingScanStatistics[T] {
    return super.get(value)
  }
}