import { Popover, PopoverInteractionKind, Position } from '@blueprintjs/core'
import styles from 'Components/ScanPage/styles.module.scss'
import { List } from 'immutable'
import React from 'react'

class Channel extends React.Component {
  render() {
    const { channels, actions } = this.props
    const isChannelsEmpty =
      (List.isList(channels) && channels.isEmpty()) || (Array.isArray(channels) && channels.length === 0)

    return (
      <div className={styles.channelDimension}>
        {!isChannelsEmpty ? (
          <div className={styles.subscription}>
            {channels.map(channel => {
              const channelActions = actions.valueSeq().filter(action => action.channelId === channel.id)

              return (
                <li key={channel.id}>
                  {channelActions.isEmpty() ? (
                    <ul key={channel.id}>{channel.name}</ul>
                  ) : (
                    <li className={styles.channelAction}>
                      <Popover
                        interactionKind={PopoverInteractionKind.HOVER}
                        popoverClassName='bp5-popover-content-sizing'
                        position={Position.BOTTOM}
                        disabled={channelActions.isEmpty() && actions.isEmpty()}
                        content={
                          <div>
                            {channelActions.valueSeq().map(action => (
                              <div key={action.id}>{action.message}</div>
                            ))}
                          </div>
                        }
                      >
                        <div>
                          <div>{channel.name}</div>
                        </div>
                      </Popover>
                    </li>
                  )}
                </li>
              )
            })}
          </div>
        ) : (
          <div className={styles.notAvailableforSubscription}> - </div>
        )}
      </div>
    )
  }
}

export default Channel
