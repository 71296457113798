import styles from 'Components/ArtworkPage/TitleAuditRow.module.scss'
import Tag from 'Components/Tag/Tag.tsx'
import AuditArtwork from 'Containers/AuditArtwork'
import TitleDetail from 'Containers/TitleDetail'
import { getClient, getSelectedScan } from 'Selectors/index'
import cx from 'classnames'
import React from 'react'
import { connect } from 'react-redux'
import { Link, Route, withRouter } from 'react-router-dom'

class TitleAuditRow extends React.Component {
  onClose = () => {
    const { history, rootPath } = this.props;
    history.push(rootPath);
  };
  render() {
    const { title, platforms, titleAudit, rootPath, client, scan } = this.props
    const featuredTag = scan.enableMerchandizing && titleAudit && titleAudit.promoted

    const headingClasses = cx(styles.titleAuditRowHeading, {
      [styles.tvHeading]: title && title.titleTypeCode === 'tv_season',
      [styles.movieHeading]: title && title.titleTypeCode === 'movie',
    })

    const rowClasses = cx(styles.titleAuditRow, {
      [styles.tvRow]: title && title.titleTypeCode === 'tv_season',
      [styles.movieRow]: title && title.titleTypeCode === 'movie',
    })

    const titleDetailLink = `${rootPath}/title/${title && title.id}`
    return (
      <div className={styles.titleAuditRowPosition}>
        <div className={rowClasses}>
          <Link
            to={titleDetailLink}
            className={headingClasses}
            onClick={e => {
              if (e.target && !e.currentTarget.contains(e.target)) {
                e.preventDefault()
              }
            }}
          >
            {title && <h3>{title.name}</h3>}
            {title && <div className={styles.titleType}>{title.titleType}</div>}
            {client && client.tagsEnabled && titleAudit && <Tag featuredTag={featuredTag} titleAudit={titleAudit} />}
            {title && titleAudit && (
              <Route
                path={titleDetailLink}
                render={props => <TitleDetail {...props} onClose={this.onClose} title={title} titleAudit={titleAudit} />}
              />
            )}
          </Link>
          {platforms &&
            titleAudit &&
            platforms
              .valueSeq()
              .map(
                platform =>
                  platform && (
                    <AuditArtwork
                      key={`${titleAudit.id}-${platform.id}`}
                      titleAuditId={titleAudit.id}
                      platformId={platform.id}
                    />
                  )
              )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { titleAudit } = ownProps
  const client = getClient(state)
  const scan = getSelectedScan(state)
  const title = titleAudit && titleAudit.getTitle(state)

  return {
    titleAudit,
    client,
    title,
    scan,
  }
}

export { TitleAuditRow as UnconnectedTitleAuditRow }

const ConnectedTitleAuditRow = connect(mapStateToProps)(TitleAuditRow)
const ConnectedTitleAuditRowWithRouter = withRouter(ConnectedTitleAuditRow)
export default ConnectedTitleAuditRowWithRouter
