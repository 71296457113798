import React, { useLayoutEffect, useRef } from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5percent from '@amcharts/amcharts5/percent'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import styles from '../styles.module.scss'
import { MerchandizingScanStatistic } from 'Interfaces/*'
import { formatGraphData } from '../../utils'
import { setAdapter, setTooltipAdapter, createPieChart, createLegend } from '../chartUtils'

type MultiDataPieChartTypes = {
  metric: string
  data: MerchandizingScanStatistic[]
  scanDate: string
}

const MultiDataPieChart: React.FC<MultiDataPieChartTypes> = ({ data, metric, scanDate }) => {
  const chartRef = useRef(null)

  useLayoutEffect(() => {
    const root = am5.Root.new(chartRef.current)

    root.setThemes([am5themes_Animated.new(root)])

    root._logo.dispose()

    const chart = createPieChart(root, 50)

    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: metric,
        categoryField: 'studioName',
      })
    )

    setAdapter(series.slices.template, 'fill', 'studioColor')
    setAdapter(series.slices.template, 'stroke', 'studioColor')
    setAdapter(series.ticks.template, 'stroke', 'studioColor')

    setTooltipAdapter(series.slices.template.adapters, metric, scanDate)

    series.ticks.template.setAll({
      strokeWidth: 3,
      strokeOpacity: 1,
    })

    const formattedData = formatGraphData(data)

    series.data.setAll(formattedData)

    createLegend(root, series)

    return () => {
      root.dispose()
    }
  }, [data, metric])

  return <div ref={chartRef} className={styles.chartWrapper}></div>
}

export default MultiDataPieChart
