import React from 'react'
import { retrieveDefaultMerchandizingScan, setSelectedScan } from 'Actions/scanActions'
import NavPopover from './NavPopover'
import { useClientContext } from 'Contexts/ClientContext'
import { useNavPaths } from 'Hooks/useNavPaths'
import { useScans } from 'Hooks/useScans'
import moment from 'moment'
import { matchPath, useHistory } from 'react-router'

type HistoryPopoverProps = {
  open: boolean
  onOpenClick: (_isOpen: boolean) => void
}

const HistoryPopover: React.FC<HistoryPopoverProps> = ({ open, onOpenClick }) => {
  const { client, isAdminRoute } = useClientContext()
  const { getHistoryPath } = useNavPaths({ clientApp: !isAdminRoute })
  const history = useHistory()
  const { scans, hasMoreToLoad, handleInfiniteLoad } = useScans()

  const matchPathHelper = (path: string) => matchPath(window.location.pathname, { path, strict: false })
  const isOnMerchPage = ['/v2/clients/:id/merchandizing/scans', '/dashboard/merch/:scan_id/pages/:id'].some(
    matchPathHelper
  )

  const handleNavigateToHistory = async (scanId: string) => {
    if (isOnMerchPage) {
      await navigateToMerchPage(scanId)
    } else {
      navigateToHistoryPage(scanId)
    }
  }

  const navigateToMerchPage = async (scanId: string) => {
    setSelectedScan(scanId)
    const res = await retrieveDefaultMerchandizingScan(scanId)
    if (res?.merchandizingScan) {
      const { id: merchScanId, rootPageId } = res.merchandizingScan
      window.location.href = `${getHistoryPath({ scanId, clientId: client.id, merchScanId, rootPageId }).url}${history.location.search}`
    }
  }

  const navigateToHistoryPage = (scanId: string) => {
    window.location.href = `${getHistoryPath({ scanId, clientId: client.id }).url}${history.location.search}`
  }

  const items = scans.map(scan => ({
    id: scan.id,
    title: moment(scan.publishedAt || scan.createdAt, 'YYYY-MM-DD HH:mm:ss.SSSSSSSSS Z').format('MMMM Do YYYY'),
  }))

  return (
    <NavPopover
      items={items}
      isOpen={open}
      setIsOpen={onOpenClick}
      next={handleInfiniteLoad}
      hasMore={hasMoreToLoad}
      iconClassName='bp5-icon-timeline-events'
      historyPopover={true}
      onItemClick={handleNavigateToHistory}
    />
  )
}

export default HistoryPopover
