import React from 'react';
import { Button, Checkbox, Popover, PopoverInteractionKind, Position, Tooltip } from '@blueprintjs/core';
import SingleScan from '../Merchandizing/SingleScan';
import { MerchandizingScan, Scan } from 'Interfaces/*';
import styles from './Toolbar.module.scss';
import cx from 'classnames';
import { SELECT_PLATFORMS } from './constants';
import MerchScansList from './MerchScanList';

type Props = {
  groupedAssociatedMerchScans: Record<string, MerchandizingScan[]>;
  scan: Scan;
  toggleAssociatedMerchandizingScan: (ids: string[]) => void;
};

const renderAssociatedMerchScans = (
  groupedAssociatedMerchScans: Record<string, MerchandizingScan[]>,
  toggleAssociatedMerchandizingScan: (ids: string[]) => void
) => Object.keys(groupedAssociatedMerchScans).map(platform => (
  <MerchScansList
    key={platform}
    platform={platform}
    merchScans={groupedAssociatedMerchScans[platform]}
    toggleAssociatedMerchandizingScan={toggleAssociatedMerchandizingScan}
  />
));

const PlatformSelector: React.FC<Props> = ({ groupedAssociatedMerchScans, scan, toggleAssociatedMerchandizingScan }) => {
  const hasMerchScans = Object.keys(groupedAssociatedMerchScans).length > 0;

  return (
    <Popover
      interactionKind={PopoverInteractionKind.CLICK}
      position={Position.BOTTOM_RIGHT}
      className='bp5-align-right'
      content={
        <div data-testid='platformsMenu' className={styles.merchPtMenu}>
          {hasMerchScans ? (
            renderAssociatedMerchScans(groupedAssociatedMerchScans, toggleAssociatedMerchandizingScan)
          ) : (
            <SingleScan scan={scan} />
          )}
        </div>
      }
    >
      <Tooltip content={SELECT_PLATFORMS} position={Position.BOTTOM_LEFT}>
        <Button
          data-testid='selectPlatform'
          type='button'
          className={cx('bp5-align-left', styles.btnDefault)}
        >
          {SELECT_PLATFORMS}
        </Button>
      </Tooltip>
    </Popover>
  );
};

export default PlatformSelector;