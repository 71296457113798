import React from 'react';
import { Button, Popover, PopoverInteractionKind, Position } from '@blueprintjs/core';
import {
  SELECT_METRIC,
  SHARE_OF_VOICE,
  CARET_DOWN,
  CARET_UP,
  MPV,
} from './constants'
import cx from 'classnames';
import styles from './Toolbar.module.scss';

type MetricSelectorProps = {
  metric: string;
  setMetric: (metric: string) => void;
  caretIcon: string;
  setCaretIcon: (icon: string) => void;
};

type Metric = {
  value: string;
  label: string;
}

const MetricSelector: React.FC<MetricSelectorProps> = ({ metric, setMetric, caretIcon, setCaretIcon }) => {
  
  const METRICS: Metric[] = [
    { value: 'totalMpv', label: MPV },
    { value: 'totalShareOfVoice', label: SHARE_OF_VOICE },
  ]

  const getLabelByValue = (value: string): string | undefined => {
    const metric = METRICS.find(metric => metric.value === value);
    return metric ? metric.label : undefined;
  }

  return (
    <Popover
      interactionKind={PopoverInteractionKind.CLICK}
      position={Position.BOTTOM_RIGHT}
      className='bp5-align-right'
      content={
        <div className={styles.metricMenu}>
          <ul className='bp5-menu' data-testid="metric-menu">
            {METRICS.map(item => (
              <button key={item.value} onClick={() => setMetric(item.value)} className='bp5-menu-item' data-testid={`metric-item-${item.value}`}>
              {item.label}
              </button>
            ))}
          </ul>
        </div>
      }
    >
      <Button
        className={cx('bp5-align-left', styles.btnDefault)}
        text={metric ? getLabelByValue(metric) : SELECT_METRIC}
        onClick={() => setCaretIcon(caretIcon === CARET_DOWN ? CARET_UP : CARET_DOWN)}>
        <span className={cx('bp5-icon-standard bp5-align-right', caretIcon, styles.caretIcon)}></span>
      </Button>
    </Popover>
  );
};

export default MetricSelector;