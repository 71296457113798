import { readEndpoint } from 'Actions/apiActions'
import StudioRecord from 'Records/studioRecord'
import { HIGHLIGHT_TITLES } from 'Reducers/app/studioReducer'
import { OrderedMap } from 'immutable'

export const loadStudios = (studios, reset = false) => ({
  type: 'LOAD_STUDIOS',
  studios,
  reset,
})

export const studioRecordsMapper = (memo, item) => {
  return memo.set(
    item?.id,
    new StudioRecord({
      ...item,
    })
  )
}

export const retrieveStudios = () => dispatch => {
  return readEndpoint('studios?filter[presentInMerchandisingFilter]=true').then(response => {
    const studios = response?.studios?.reduce(studioRecordsMapper, new OrderedMap())
    dispatch(loadStudios(studios))
  })
}

export const highlightTitles = studioIds => dispatch => {
  dispatch({
    type: HIGHLIGHT_TITLES,
    studioIds,
  })
}

export const normalizeAndLoadStudios =
  (data, reset = false) =>
    dispatch => {
      const studios = data.reduce((memo, item) => {
        return memo.set(item.id, new StudioRecord({ ...item }))
      }, new OrderedMap())

      return dispatch(loadStudios(studios, reset))
    }
