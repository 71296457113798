import React, { useCallback, useMemo, useState } from 'react'
import { MenuItem } from '@blueprintjs/core'
import PerformanceButtons from './PerformanceButtons'
import { MultiSelect } from '@blueprintjs/select'
import PlatformSelector from './PlatformSelector'
import MetricSelector from './MetricSelector'

import { Client } from 'Records/clientRecord'
import { StudioRecord } from 'Records/studioRecord'
import useToolbarActions from 'Components/ScanPage/useToolbarActions'
import { groupAssociatedMerchScansByPlatform } from 'Components/Merchandizing/helpers'

import { SELECT_DISTRIBUTORS, CARET_DOWN, PerformancePageEnum } from './constants'

import { MerchandizingScan, Scan, Competitor } from 'Interfaces/*'
import { List } from 'immutable'

import styles from './Toolbar.module.scss'
import cx from 'classnames'

type Props = {
  client: Client
  setMetric: (metric: string) => void
  metric: string
  associatedMerchScans: List<MerchandizingScan>
  scan: Scan
  performancePage: PerformancePageEnum
  competitors: Competitor[]
  handleSetPerformancePage: (page: string) => void
}

const Toolbar: React.FC<Props> = ({
  setMetric,
  metric,
  associatedMerchScans,
  scan,
  performancePage,
  competitors,
  handleSetPerformancePage,
}) => {
  const groupedAssociatedMerchScans = useMemo(
    () => groupAssociatedMerchScansByPlatform(associatedMerchScans),
    [associatedMerchScans]
  )
  const [caretIcon, setCaretIcon] = useState<string>(CARET_DOWN)

  const { toggleClientCompetitor, toggleAssociatedMerchandizingScan } = useToolbarActions({})

  const handleTagRemove = useCallback(
    (item: string) => {
      const selectedTag = competitors.find(competitor => competitor.name === item)
      if (selectedTag) {
        toggleClientCompetitor(selectedTag.id)
      }
    },
    [competitors, toggleClientCompetitor]
  )

  const multiSelectItem = (
    item: StudioRecord,
    { handleClick }: { handleClick: (event: React.MouseEvent<HTMLElement>) => void }
  ) => <MenuItem key={item.id} text={item.name} onClick={handleClick} active={item.selected} />

  return (
    <nav className={cx(styles.header, 'bp5-navbar')}>
      <div className={cx('bp5-navbar-group bp5-align-left', styles.toolbarGroup)}>
        <PerformanceButtons performancePage={performancePage} handleSetPerformancePage={handleSetPerformancePage} />
      </div>
      <div className={cx('bp5-navbar-group bp5-align-right', styles.toolbarGroup)}>
        <div className='bp5-button-group'>
          <PlatformSelector
            groupedAssociatedMerchScans={groupedAssociatedMerchScans}
            scan={scan}
            toggleAssociatedMerchandizingScan={toggleAssociatedMerchandizingScan}
          />
        </div>
        <div className={cx(styles.divider, 'bp5-navbar-divider')} />
        <div className='bp5-button-group'>
          <MultiSelect
            items={competitors}
            selectedItems={competitors.filter(item => item.selected)}
            onItemSelect={(item: StudioRecord) => toggleClientCompetitor(item.id)}
            itemRenderer={multiSelectItem}
            tagRenderer={(item: StudioRecord) => item.name}
            tagInputProps={{
              onRemove: (item: StudioRecord) => handleTagRemove(item),
              className: styles.tagWrapper,
              autoResize: true,
              tagProps: {
                minimal: true,
                className: styles.tag,
              },
            }}
            placeholder={SELECT_DISTRIBUTORS}
          />
        </div>
        <div className={cx(styles.divider, 'bp5-navbar-divider')} />
        <div className='bp5-button-group'>
          <MetricSelector metric={metric} setMetric={setMetric} caretIcon={caretIcon} setCaretIcon={setCaretIcon} />
        </div>
      </div>
    </nav>
  )
}

export default Toolbar
