import React from 'react';
import { Checkbox} from '@blueprintjs/core';
import { MerchandizingScan } from 'Interfaces/*';

const MerchScansList: React.FC<{
  platform: string;
  merchScans: MerchandizingScan[];
  toggleAssociatedMerchandizingScan: (ids: string[]) => void;
}> = ({ platform, merchScans, toggleAssociatedMerchandizingScan }) => (
  <div key={platform} className='bp5-menu'>
    <span>{platform}</span>
    <ul className='bp5-menu'>
      {merchScans.map(ams => (
        ams && (
          <li key={ams.id}>
            <Checkbox
              checked={ams.selected}
              onChange={() => toggleAssociatedMerchandizingScan([ams.id])}
            >
              {ams.name}
            </Checkbox>
          </li>
        )
      ))}
    </ul>
  </div>
);

export default MerchScansList