import React from 'react'
import cx from 'classnames'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setIncludes } from 'Actions/scanActions'
import {
  getHasMoreTitleAuditsToLoad,
  getRootPath,
  getScanFilters,
  getSelectedPlatforms,
  getSelectedPlatformsWithShowFlags,
  getSelectedScan,
  getTitleAuditsForScan,
  getTitlesForScan,
  getUI,
} from 'Selectors/index'

import { Spinner } from '@blueprintjs/core'
import { retrieveTags } from 'Actions/tagActions'
import {
  ARTWORK_INCLUDES,
  resetTitleAudits,
  retrieveMoreTitleAudits,
  retrieveTitleAudits,
} from 'Actions/titleAuditActions'
import { setRootPath } from 'Actions/uiActions'
import ArtworkNotFound from 'Components/ArtworkPage/ArtworkNotFound'
import Grid from 'Components/ArtworkPage/Grid'
import Toolbar from 'Components/ArtworkPage/Toolbar'
import styles from 'Components/ArtworkPage/styles.module.scss'
import { useClientContext } from 'Contexts/ClientContext'
import usePreserveFilter from 'Hooks/usePreserveFilter'
import useScan from 'Hooks/useScan'
import type { RouteComponentProps } from 'react-router-dom'
import { withRouter } from 'react-router-dom'

interface Props extends RouteComponentProps<{ scanId: string }> {
  scanId: number
  competitors: boolean
}

const ArtworkPage = ({ match, scanId }: Props) => {
  const dispatch = useDispatch()
  const { client, clientId } = useClientContext()
  const scan = useSelector(state => getSelectedScan(state))
  const scanView = useSelector(state => getUI(state).get('scanView'))
  const filter = useSelector(state => getScanFilters(state))
  const titleAudits = useSelector(state => getTitleAuditsForScan(state, { scan }))
  const platforms = useSelector(state => getSelectedPlatforms(state))
  const platformsWithFlags = useSelector(state => getSelectedPlatformsWithShowFlags(state))
  const titles = useSelector(state => getTitlesForScan(state, { scan }))
  const rootPath = useSelector(state => getRootPath(state))
  const hasMoreTitleAuditsToLoad = useSelector(state => getHasMoreTitleAuditsToLoad(state))

  const dragonflyEnabled = client?.dragonflyEnabled
  const dragonflyArtworkUrl = client?.dragonflyArtworkUrl
  const { movies: movieFilter, tv: seriesFilter, all: allFilter, query: titleQuery } = filter

  useScan(scanId)

  usePreserveFilter({ enabled: !!scan })

  useEffect(() => {
    dispatch(resetTitleAudits())
    dispatch(setIncludes(ARTWORK_INCLUDES))
    dispatch(setRootPath(match.url))
  }, [dispatch, match.url])

  useEffect(() => {
    if (!scan?.id) return

    dispatch(retrieveTags(clientId)).then(() => {
      dispatch(retrieveTitleAudits(scan.id))
    })
  }, [dispatch, scan?.id, clientId])

  return (
    <div>
      <div className={cx('bp5-focus-disabled', styles.root)}>
        <Toolbar
          scan={scan}
          scanView={scanView}
          allFilter={allFilter}
          movieFilter={movieFilter}
          seriesFilter={seriesFilter}
          titleQuery={titleQuery}
          filter={filter}
          client={client}
          dragonflyEnabled={dragonflyEnabled}
          dragonflyArtworkUrl={dragonflyArtworkUrl}
        />
        {hasMoreTitleAuditsToLoad && titleAudits?.size === 0 && (
          <div className={styles.spinnerWrapper}>
            <Spinner />
          </div>
        )}
        {!hasMoreTitleAuditsToLoad && titleAudits?.size === 0 && <ArtworkNotFound />}
        {titleAudits?.size > 0 && (
          <Grid
            scan={scan}
            scanId={scan?.id}
            titleAudits={titleAudits}
            titles={titles}
            platforms={platforms}
            setIncludes={setIncludes}
            retrieveMoreTitleAudits={() => dispatch(retrieveMoreTitleAudits())}
            rootPath={rootPath}
            platformsWithFlags={platformsWithFlags}
            hasMoreTitleAuditsToLoad={hasMoreTitleAuditsToLoad}
          />
        )}
      </div>
    </div>
  )
}

export default withRouter(ArtworkPage)
