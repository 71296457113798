import React, { useEffect } from 'react'
import ContainerDimensions from 'react-container-dimensions'
import { withRouter } from 'react-router-dom'
import Infinite from 'react-infinite'
import styled from 'styled-components'

import TitleRow from 'Containers/TitleRow.tsx'
import useInfiniteScroll from 'hooks/useInfiniteScroll'

import GhostRow from 'Components/ContainerPage/GhostRow'
import styles from 'Components/ContainerPage/Grid.module.scss'
import cssVariables from 'Components/ContainerPage/variables.scss'
import ErrorBoundary from 'Components/ErrorBoundary'

const GridContainer = styled.div`
  min-width: calc(
    ${cssVariables.rowHeaderWidth} + ${cssVariables.gridCellTotalWidth} + ${cssVariables.gridCellTotalWidth} *
      ${props => props.cells}
  );
`

const Grid = ({
  titles,
  platforms,
  platformsWithFlags,
  retrieveTitles,
  showContainerView,
  retrieveMoreTitles,
  hasMoreTitlesToLoad,
}) => {
  const { isInfiniteLoading, handleInfiniteLoad, elementInfiniteLoad } = useInfiniteScroll(
    retrieveMoreTitles,
    hasMoreTitlesToLoad,
    styles.spinnerWrapper
  )

  useEffect(() => {
    retrieveTitles()
    showContainerView()
    window.scrollTo(0, 0)
  }, [retrieveTitles, showContainerView])

  const heightsForInfinite = () => {
    return titles
      .valueSeq()
      .map(() => {
        const titleHeight = parseInt(cssVariables.gridCellHeight)
        return titleHeight || 0
      })
      .toArray()
  }

  const gridCellWidth = parseInt(cssVariables.gridCellWidth)
  const gridCellHeight = parseInt(cssVariables.gridCellHeight)

  return (
    <GridContainer className={styles.gridContainer} cells={platforms.size}>
      <ErrorBoundary>
        <ContainerDimensions>
          {({ width, height }) => {
            const rowBodyWidth = width - parseInt(cssVariables.rowHeaderWidth)
            const ghostCells = Math.trunc((rowBodyWidth - platforms.size * gridCellWidth) / gridCellWidth) + 1
            const ghostRowCount = Math.max(0, Math.trunc((height - titles.size * gridCellHeight) / gridCellHeight) + 1)

            const ghostRows = new Array(ghostRowCount)
              .fill(0)
              .map((_s, index) => <GhostRow key={`ghost${index}`} ghostCells={ghostCells + platforms.size} />)

            return (
              height && (
                <Infinite
                  containerHeight={height}
                  elementHeight={heightsForInfinite()}
                  onInfiniteLoad={handleInfiniteLoad}
                  infiniteLoadBeginEdgeOffset={400}
                  isInfiniteLoading={isInfiniteLoading}
                  loadingSpinnerDelegate={elementInfiniteLoad()}
                  ghostRows={ghostRows}
                >
                  {titles.valueSeq().map(title => (
                    <TitleRow
                      titleId={title.id}
                      key={title.id}
                      ghostCells={ghostCells}
                      platformsWithFlags={platformsWithFlags}
                    />
                  ))}
                </Infinite>
              )
            )
          }}
        </ContainerDimensions>
      </ErrorBoundary>
    </GridContainer>
  )
}

const ConnectedGridWithRouter = withRouter(Grid)
export default ConnectedGridWithRouter
