import { useState } from 'react';
import { Spinner } from '@blueprintjs/core';
import React from 'react';

const useInfiniteScroll = (
  retrieveMoreData, 
  hasMoreDataToLoad, 
  spinnerWrapperClassName,
  customLoadingElement = null,
) => {
  const [isInfiniteLoading, setIsInfiniteLoading] = useState(false);

  const handleInfiniteLoad = () => {
    if (!hasMoreDataToLoad) return;

    setIsInfiniteLoading(true);

    return retrieveMoreData().finally(() => {
      setIsInfiniteLoading(false);
    });
  };

  const elementInfiniteLoad = (ghostCells) => {
    if (!hasMoreDataToLoad) return;

    if (customLoadingElement) {
      return customLoadingElement?.(ghostCells);
    }

    return (
      <div className={spinnerWrapperClassName}>
        <Spinner />
      </div>
    );
  };

  return {
    isInfiniteLoading,
    handleInfiniteLoad,
    elementInfiniteLoad,
  };
};

export default useInfiniteScroll;
